.containerKeyboardVirtual{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.containerKeyboardContentVirtual{
  grid-row: 1;
  grid-column: 1/-1;
}

.containerKeysVirtual{
  position: sticky;
  grid-row: 2;
  grid-column: 1/-1;
  bottom: 0;
  text-align: end;
  z-index: 2000;
  font-size: 1.8vw;

}

.numericKeysVirtual{
  grid-column: 2;
}

.hg-theme-default .hg-button{
  height: 4vw !important;
}

.hg-button-tab, .hg-button-lock, .hg-button-shift, .hg-button-enter, .hg-button-bksp{
  background-color: #9093aa !important;
  color: white !important;
}

