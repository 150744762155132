@media only screen and (max-width: 600px) {
  .divCircle {
    border-color: rgb(111, 190, 228);
    border-radius: 30px;
    border-style: solid;
    border-width: 2px;
    padding: 10px;
    width: 34px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .paper {
    width: 688;
    padding: 28px;
    padding-left: 136;
    padding-right: 136;
    margin-top: 20px;
  }

  .Icons_WhatsappIcon {
    align-items: center;
    margin: 12px;
    width: 79px;
    height: 79px;
  }

  .title {
    font-size: 25;
    text-align: 'center';
    padding-left: 3;
    padding-right: 34;
  }

  .subtitle {
    font-size: 18;
  }

  .stepLabel {
    font-size: 18;
  }

  .Icons_lockIcon {
    width: 27px;
    height: 30px;
  }

  .button {
    margin-left: 21px;
    margin-right: 21px;
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 18px;
    text-transform: none;
    border-radius: 8px;
    width: 100%;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
  }

  .buttonAsociar {
    width: 100%;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .divCircle {
    border-color: rgb(111, 190, 228);
    border-radius: 30px;
    border-style: solid;
    border-width: 2px;
    padding: 10px;
    width: 34px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .paper {
    width: 688;
    padding: 28px;
    padding-left: 136;
    padding-right: 136;
    margin-top: 20px;
  }

  .Icons_WhatsappIcon {
    align-items: center;
    margin: 12px;
  }

  .title {
    font-size: 35;
    text-align: 'center';
    padding-left: 3;
    padding-right: 34;
  }

  .subtitle {
    font-size: 28;
  }

  .stepLabel {
    font-size: 18;
  }

  .button {
    margin-left: 21px;
    margin-right: 21px;
    margin-top: 60px;
    margin-bottom: 20px;
    font-size: 18px;
    text-transform: none;
    border-radius: 8px;
    width: 80%;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
  }

  .buttonAsociar {
    width: 80%;
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1439px) {
  .divCircle {
    border-color: rgb(111, 190, 228);
    border-radius: 30px;
    border-style: solid;
    border-width: 2px;
    padding: 10px;
    width: 34px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .paper {
    width: 688;
    padding: 28px;
    padding-left: 136;
    padding-right: 136;
    margin-top: 20px;
  }

  .Icons_WhatsappIcon {
    align-items: center;
    margin: 12px;
  }

  .title {
    font-size: 35;
    text-align: 'center';
    padding-left: 3;
    padding-right: 34;
  }

  .subtitle {
    font-size: 28;
  }

  .stepLabel {
    font-size: 18;
  }

  .button {
    margin-left: 21px;
    margin-right: 21px;
    margin-top: 60px;
    margin-bottom: 20px;
    font-size: 18px;
    text-transform: none;
    border-radius: 8px;
    width: 80%;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
  }

  .buttonAsociar {
    width: 80%;
  }
}
@media only screen and (min-width: 1440px) {
  .divCircle {
    border-color: rgb(111, 190, 228);
    border-radius: 30px;
    border-style: solid;
    border-width: 2px;
    padding: 10px;
    width: 34px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .paper {
    width: 688;
    padding: 28px;
    padding-left: 136;
    padding-right: 136;
    margin-top: 20px;
  }

  .Icons_WhatsappIcon {
    align-items: center;
    margin: 12px;
  }

  .title {
    font-size: 35;
    text-align: 'center';
    padding-left: 3;
    padding-right: 34;
  }

  .subtitle {
    font-size: 28;
  }

  .stepLabel {
    font-size: 18;
  }

  .button {
    margin-left: 21px;
    margin-right: 21px;
    margin-top: 60px;
    margin-bottom: 20px;
    font-size: 18px;
    text-transform: none;
    border-radius: 8px;
    width: 70%;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
  }
  .buttonAsociar {
    width: 70%;
  }
  .MuiInputBase-input {
    font-size: 1.3vw;
  }
  .secondaryTitle {
    width: 80%;
    margin: 0 auto !important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1023px) {
  .divCircle {
    border-color: rgb(111, 190, 228);
    border-radius: 30px;
    border-style: solid;
    border-width: 2px;
    padding: 10px;
    width: 34px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .paper {
    width: 688;
    padding: 28px;
    padding-left: 136;
    padding-right: 136;
    margin-top: 20px;
  }

  .Icons_WhatsappIcon {
    align-items: center;
    margin: 12px;
  }

  .title {
    font-size: 35;
    text-align: 'center';
    padding-left: 3;
    padding-right: 34;
  }

  .subtitle {
    font-size: 28;
  }

  .stepLabel {
    font-size: 18;
  }

  .button {
    margin-left: 21px;
    margin-right: 21px;
    margin-top: 60px;
    margin-bottom: 20px;
    font-size: 18px;
    text-transform: none;
    border-radius: 8px;
    width: 100%;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
  }

  .buttonAsociar {
    width: 100%;
  }
}
