.containerHeader{
    display: flex;
    justify-content: space-around;
    align-content: center;
    height: 100%;
}

.containerArrowHeader{
    width: 20%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
}

.arrowHeader{
    display: inline-block;
    width: 18%;
    margin-left: 17%;
    transition: all 100ms ease-in-out;
    -webkit-touch-callout: none; 
    -webkit-user-select: none; 
    -khtml-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    cursor: pointer;
}

.containerLogoHeader{
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logoHeader{
    width: 50%;
    height: 58%;
    margin-top: 3%;
}

.containerHomeHeader{
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.backgroundHomeHeader{
    width: 4.5vw;
    height: 4.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #6FBEE4;
    -webkit-box-shadow:  0px 0px 3px 1px rgba(0,0,0,0.3);
    -moz-box-shadow:  0px 0px 3px 1px rgba(0,0,0,0.3);
    box-shadow:  0px 0px 3px 1px rgba(0,0,0,0.3);
    margin-right: 35%;
    transition: all 20ms ease;
    cursor: pointer;
}

.homeHeader{
    width: 70%;
}

.backgroundKeyboardHeader{
    width: 100%;
    display: flex;
    justify-content: center;
}

.keyboardIcon{
    margin: 8px;
    width: 20%;
    height: 3em;
    fill: rgb(46, 117, 183)
}

.backgroundHomeHeader:active{
    -webkit-transform: scale(0.90);
    -moz-transform: scale(0.90);
    -o-transform: scale(0.90);
    transform: scale(0.90);
    background-color: #5ba1c2;
}

.arrowHeader:active{
    -webkit-transform: scale(0.85);
    -moz-transform: scale(0.85);
    -o-transform: scale(0.85);
    transform: scale(0.85);
}

@media only screen and (width:1366px) and (height: 768px) {
    
}