html {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Nunito';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  /* background-image: url("./assets/images/backgroundicons.svg"); */
}
body > div {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./assets/fonts/Roboto-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-regular';
  src: local('Roboto-regular'),
    url(./assets/fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-medium';
  src: local('Roboto-medium'),
    url(./assets/fonts/Roboto-Medium.ttf) format('truetype');
}

.noty_type__warning a {
  color: #1493bd;
  text-decoration: none;
}

.primary {
  color: #1b2f62;
}

/* notys */

.noty_body {
  color: #fff;
  font-size: 15px;
  padding: 12px;
}

.noty_type__success .noty-header {
  font-size: 11px;
}
.noty_type__success {
  background-color: #1f9c31 !important;
  position: relative;
  padding: 0 10px 0 20px;
  border-radius: 5px !important;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.2);
  font-size: 1.3vw;
}
.noty_custom_container {
  display: flex;
  justify-content: space-between;
}
.noty_custom_icon {
  display: flex;
  align-items: center;
  padding-right: 17px;
}

.noty_type__success img {
  bottom: 5px;
  height: 45px;
}

.noty_type__success h3 {
  margin-bottom: 0;
  margin-top: 5px;
  font-size: 15px;
}

.noty_type__success p {
  color: #fff;
}

.noty_type__success .noty-bull {
  display: inline-block;
  margin: 0 10px;
}

.noty_type__success a {
  color: #fff;
  text-decoration: none;
}

.noty_type__warning a {
  color: #1493bd;
  text-decoration: none;
}

.noty_type__error .noty-header {
  font-size: 11px;
}
.noty_type__error {
  background-color: #de636e !important;
  position: relative;
  padding: 0 10px 0 20px;
  border-radius: 5px !important;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.2);
  height: auto;
  display: flex;
  align-items: center;
  /* font-size: 1.3vw; */
}
.noty_type__error img {
  bottom: 5px;
  height: 45px;
}
.noty_type__error h3 {
  margin-bottom: 0;
  margin-top: 5px;
  font-size: 15px;
}
.noty_type__error p {
  color: #ffffff;
}
.noty_type__error .noty-bull {
  display: inline-block;
  margin: 0 10px;
}
.noty_type__error a {
  color: #fff;
  text-decoration: none;
}
.noty_close_button {
  top: 0px;
  right: 0px;
  color: #fff;
  width: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 20px;
  font-size: 20px;
  background-color: '#0000003b';
  border-bottom-left-radius: 13px;
}

/* Inputs Styles */

.MuiFormControl-root {
  /*width: 20%;*/
  padding: 1% 0 5% 0 !important;
}

.MuiFormLabel-root {
  font-size: 1.5vw;
  font-family: 'Nunito' !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #2e76b7 !important;
}
.MuiInput-underline::after {
  border-bottom: 0.15vw solid #2e76b7 !important;
}

.MuiInputBase-input {
  font-family: 'Nunito' !important;
  /* font-size: 1.7vw !important; */
  color: #7c7c7c !important;
}

.MuiInput-underline:hover:not(.Mui-disabled)::before {
  border-bottom: 0.15vw solid #6fbee4 !important;
}

.MuiSelect-select {
  padding-left: 30px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* input[type="date"]::-webkit-calendar-picker-indicator {
  display: block;
  -webkit-appearance: none;
  color: #1493bd;
} */
