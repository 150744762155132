@media only screen and (max-width: 600px) {
  .container {
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
    justify-content: 'center';
  }
  .MainPaper {
    width: 80%;
  }
  .bgCircleLeft {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 93px;
    z-index: -1;
  }

  .bgCircleRight {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 54.9px;
    height: 52.8px;
    z-index: -1;
  }

  .bgCircleTop {
    position: absolute;
    right: 0;
    top: 0;
    width: 128px;
    height: 78px;
  }

  .Logos_carrefourTarjetaLogoWhite {
    position: absolute;
    right: 17px;
    top: 17px;
    width: 78px;
    height: 36px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .container {
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
    justify-content: 'center';
  }
  .MainPaper {
    width: 70%;
  }
  .bgCircleLeft {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .bgCircleRight {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .bgCircleTop {
    position: absolute;
    right: 0;
    top: 0;
  }

  .Logos_carrefourTarjetaLogoWhite {
    position: absolute;
    right: 17px;
    top: 17px;
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1439px) {
  .container {
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
    justify-content: 'center';
  }
  .MainPaper {
    width: 70%;
  }
  .bgCircleLeft {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .bgCircleRight {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .bgCircleTop {
    position: absolute;
    right: 0;
    top: 0;
  }

  .Logos_carrefourTarjetaLogoWhite {
    position: absolute;
    right: 17px;
    top: 17px;
  }
}
@media only screen and (min-width: 1440px) {
  .container {
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
    justify-content: 'center';
  }
  .MainPaper {
    width: 50%;
  }
  .bgCircleLeft {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .bgCircleRight {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .bgCircleTop {
    position: absolute;
    right: 0;
    top: 0;
  }

  .Logos_carrefourTarjetaLogoWhite {
    position: absolute;
    right: 17px;
    top: 17px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1023px) {
  .container {
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
    justify-content: 'center';
  }
  .MainPaper {
    width: 70%;
  }
  .bgCircleLeft {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 93px;
    z-index: -1;
  }

  .bgCircleRight {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 54.9px;
    height: 52.8px;
    z-index: -1;
  }

  .bgCircleTop {
    position: absolute;
    right: 0;
    top: 0;
    width: 128px;
    height: 78px;
  }

  .Logos_carrefourTarjetaLogoWhite {
    position: absolute;
    right: 17px;
    top: 17px;
    width: 78px;
    height: 36px;
  }
}
