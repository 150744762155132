/*------Available-----*/
.containerAvailableAccess {
  display: grid;
  grid-template-columns: repeat(5, 20%);
  grid-template-rows: repeat(20, 5vh);
}

/*------Header--------*/
.containerHeaderAvailableAccess {
  height: 25vh;
}
/*-----*/

.children {
  display: flex;
  flex-direction: column;
  height: 79%;
}

/*-----Title------*/

.containerTextSuperior {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1%;
  width: 100%;
}

.containerTitle{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  width: 3.2%;
  margin-right: 1%;
}

.title{
  font-size: 1.8vw;
  color: #2e76b7;
  text-align: center;
}

.subtitle {
  font-size: 1.7vw;
  color: #6FBEE4;
}

/*----Inputs------*/
.containerInputsAvailableAccess {
  display: flex;
  margin-left: 7vw;
  font-family: "Nunito";
  margin-top: 1%;
}

.containerInputsAvailableAccess {
  display: flex;
  margin-left: 7vw;
  font-family: "Nunito";
  margin-top: 1%;
}

.containerSelectAvailableAccess {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 27.6%;
}

.selectAvailableAccess {
  display: flex;
  width: 100%;
  border-bottom: 0.2vw solid #2e76b7;
}

.labelSelectAvailableAccess {
  font-size: 1.8vw;
  color: #7c7c7c;
}

.idIconAvailableAccess {
  width: 17%;
  margin-right: 5%;
}

.selectAvailableAccess > .MuiInput-root {
  width: 78%;
  color: #7c7c7c;
}

.selectAvailableAccess > .MuiSelect-select.MuiSelect-select {
  display: flex;
}

.selectAvailableAccess > .MuiInputBase-input {
  display: flex;
}

.selectAvailableAccess > .MuiInputBase-root {
  color: #7c7c7c !important;
  font-size: 2.3vw !important;
  font-family: "Nunito" !important;
}

.selectAvailableAccess > .MuiMenuItem-root {
  color: #7c7c7c !important;
  font-size: 2.3vw !important;
  font-family: "Nunito" !important;
}

.selectAvailableAccess > .MuiInput-underline::before {
  border-bottom: none !important;
}

.selectAvailableAccess > .MuiInput-underline::after {
  border-bottom: none !important;
}

.selectAvailableAccess > .MuiSelect-icon {
  color: #2e76b7;
}

.containerInputAvailableAccess {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 8%;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  padding-left: 7vw;
  padding-right: 7vw;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2%;
}

.labelInputAvailableAccess {
  color: #7c7c7c;
  font-size: 1.8vw;
}

.inputAvailableAccess {
  color: #7c7c7c !important ;
  font-size: 2.4vw;
  border: none;
  border-bottom: 0.2vw solid #2e76b7;
  padding: 5px 0 7px;
  width: 27.3vw;
}

.inputAvailableAccess:focus {
  outline: none;
}

/*-----Numeric Keyboard-----*/
.containerNumericKeyboardAvailableAccess {
  grid-column: 2 / span 3;
  grid-row: 10 / span 7;
  width: 46vw;
  height: 35.5vh;
  justify-self: center;
}

/*------Button------*/
.containerButtonAvailableAccess {
  grid-column: 2 / span 3;
  grid-row: 19 / span 2;
  display: flex;
  justify-content: center;
}

.buttonAvailableAccess {
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2e76b7;
  width: 70%;
  color: white;
  border-radius: 1vw;
  font-size: 2.5vw;
  margin-top: -7%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.MuiCircularProgress-colorPrimary {
  color: #6fbee4 !important;
}

.main-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mainContainer{
  height: 100vh;
  
}

@media only screen and (width: 1366px) and (height: 768px) {
  .children{
    height: 83%;
  }
}

@media only screen and (max-height: 600px) {
  .mainContainer{
    overflow: scroll;
  }
}

